<template>
  <div>
    <Header />
    <Mobileheader />
    <div class="mediumbreak"></div>

    <h1 class="service_header" data-aos="fade-up" data-aos-duration="3000"><b>Developers</b></h1>
    <div data-aos="fade-up" data-aos-duration="3000">
      <b-container>
        <b-row>
          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg1
                    >+</b-button
                  >
                </div>
              </div>
            </div>
          </b-col>

          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg2
                    >+</b-button
                  >
                </div>
              </div>
            </div></b-col
          >
          <b-col md="3" lg="3" sm="3" class="mediumbreak"
            ><div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg3
                    >+</b-button
                  >
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg4
                    >+</b-button
                  >
                </div>
              </div>
            </div></b-col
          >
          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg5
                    >+</b-button
                  >
                </div>
              </div>
            </div></b-col
          >
          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="services_card">
              <b-img
                src="@/assets/images/Home/image_middle.png"
                class="services_card_image full-width"
              >
              </b-img>
              <div class="services_card_overlay">
                <div class="services_card_button">
                  <b-button
                    variant="success"
                    class="services_card_button_size"
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-lg6
                    >+</b-button
                  >
                </div>
              </div>
            </div></b-col
          >
        </b-row>

        <b-modal id="modal-lg1" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
        </b-modal>

        <b-modal id="modal-lg2" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
          <!-- <b-img
            src="https://placekitten.com/480/210"
            class="full-width"
          ></b-img> -->
        </b-modal>

        <b-modal id="modal-lg3" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
          <!-- <b-img
            src="https://placekitten.com/480/210"
            class="full-width"
          ></b-img> -->
        </b-modal>

        <b-modal id="modal-lg4" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
          <!-- <b-img
            src="https://placekitten.com/480/210"
            class="full-width"
          ></b-img> -->
        </b-modal>
        <b-modal id="modal-lg5" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
          <!-- <b-img
            src="https://placekitten.com/480/210"
            class="full-width"
          ></b-img> -->
        </b-modal>
        <b-modal id="modal-lg6" hide-footer cancel-disabled centered size="lg">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1500"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
            <b-carousel-slide
              :img-src="require('@/assets/images/Home/image_middle.png')"
            />
          </b-carousel>
          <!-- <b-img
            src="https://placekitten.com/480/210"
            class="full-width"
          ></b-img> -->
        </b-modal>
      </b-container>
       <div class="largebreak"></div>
    </div>

    <div class="largebreak"></div>

    <Footer />
  </div>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BButton,
  BModal,
  BCarousel,
  BCarouselSlide,
  VBModal,
} from "bootstrap-vue";
import Header from "@/views/components/header.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "@/views/components/Footer.vue";

import Ripple from "vue-ripple-directive";

import Mobileheader from "@/views/components/mobileheader.vue";

export default {
  components: {
    BButton,
    Footer,
    BImg,
    BCarousel,
    BCarouselSlide,
    BContainer,
    // BButton,
    // BImg,
    BRow,
    BCol,
    BModal,
    // BCardText,
    // BLink,
    Header,
    Mobileheader,
  },
  created() {
    AOS.init();
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
